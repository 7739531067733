import { template as template_00f87ae16eb946b2899f02f6ea3c3e99 } from "@ember/template-compiler";
const WelcomeHeader = template_00f87ae16eb946b2899f02f6ea3c3e99(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
