import { template as template_02a768d64a3c49b3a64f74a6f0f59af6 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import icon from "discourse-common/helpers/d-icon";
export default class FKErrors extends Component {
    concatErrors(errors) {
        return errors.join(", ");
    }
    static{
        template_02a768d64a3c49b3a64f74a6f0f59af6(`
    <p class="form-kit__errors" id={{@id}} aria-live="assertive" ...attributes>
      <span>
        {{icon "triangle-exclamation"}}
        {{this.concatErrors @error.messages}}
      </span>
    </p>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
