import { template as template_7aa0df181eac4de7858ef4bec3676e71 } from "@ember/template-compiler";
const FKText = template_7aa0df181eac4de7858ef4bec3676e71(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
